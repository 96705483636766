'use strict'

const _ = require('lodash')
const santaTypesDefinitions = require('../../../definitions/santaTypesDefinitions')
const inlineContentMixin = require('../../../mixins/inlineContentMixin')
const overflowWrapperMixin = require('../../../mixins/overflowWrapperMixin')
const scrollMixin = require('../../../mixins/scrollMixin/scrollMixin')
const skinsJson = require('../skins/skins.json')
const skinBasedComp = require('../../../mixins/skinBasedComp')
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types')

module.exports = ({defaultSkinName, compType, displayName = 'WixContainer', includeScrollMixin = false, legacyInlineContent = false, pointerEventsNone = false, childrenRendererParams, extraPropTypes = {}, getExtraRootProps = _.noop}) => {
    // Create ordered skins where default skin is first
    const skins = _.pick(skinsJson, [defaultSkinName, ..._.keys(skinsJson)])

    return createReactClass({
        displayName,

        mixins: [
            ...includeScrollMixin ? [scrollMixin] : [],
            skinBasedComp(skins),
            overflowWrapperMixin,
            ...legacyInlineContent ? [] : [inlineContentMixin]
        ],

        propTypes: _.assign({
            isListItem: PropTypes.bool,
            zIndex: PropTypes.number,
            isResponsive: santaTypesDefinitions.RendererModel.isResponsive,
            isMeshLayoutMechanism: santaTypesDefinitions.Layout.isMeshLayoutMechanism
        }, extraPropTypes),

        statics: {
            getComponentSkins: () => skins,
            compType,
            behaviors: {
                scrollForward: {methodName: 'scrollForward'},
                scrollBackward: {methodName: 'scrollBackward'}
            }
        },

        scrollForward() {
            this.scrollInnerContent('forward')
        },

        scrollBackward() {
            this.scrollInnerContent('backward')
        },

        getA11yProps() {
            const a11y = _.get(this.props, ['compData', 'a11y'], {})
            const attributes = ['label', 'live', 'atomic', 'current', 'hidden'].reduce((acc, curr) => {
                acc[`aria-${curr}`] = a11y[curr]
                return acc
            }, {})
            const {role, tabindex: tabIndex} = a11y
            return _.omitBy(_.assign({role, tabIndex}, attributes), _.isUndefined)
        },

        getSkinProperties() {
            const {isMeshLayoutMechanism, isResponsive} = this.props
            const a11yProps = this.getA11yProps()
            const rootProps = {
                'data-is-absolute-layout': !isMeshLayoutMechanism && !isResponsive,
                style: _.assign(isMeshLayoutMechanism && pointerEventsNone ? {
                    pointerEvents: 'none'
                } : {}, _.isNumber(this.props.zIndex) && {zIndex: this.props.zIndex})
            }

            return {
                '': _.assign(
                    rootProps,
                    this.props.isListItem && {role: 'listitem'},
                    getExtraRootProps(this.props),
                    a11yProps
                ),
                loader: {
                    style: isMeshLayoutMechanism && pointerEventsNone ? {
                        pointerEvents: 'none'
                    } : {}
                },
                inlineContent: this.getInlineContent({
                    style: isMeshLayoutMechanism && pointerEventsNone ? {
                        pointerEvents: 'none'
                    } : {},
                    children: legacyInlineContent ? this.props.children : this.getChildrenRenderer(childrenRendererParams)
                })
            }
        }
    })
}
