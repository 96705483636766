/* Autogenerated file. Do not modify */
'use strict'
const skins = {}
 skins['platform.components.skins.AppWidgetLoaderSkin'] = {
  "react": [
    [
      "div",
      "loader",
      [
        "_control-preloader"
      ],
      {}
    ]
  ],
  "css": {
    "%": "display:flex;align-items:center;justify-content:center;",
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%_control-preloader": "width:36px;height:72px;margin-left:-18px;position:absolute;overflow:hidden;transform-origin:100% 50%;animation:semi-rotate 1s linear infinite;",
    "%_control-preloader::before,%_control-preloader::after": "content:'';top:0;left:0;right:-100%;bottom:0;border:3px solid currentColor;border-color:currentColor transparent transparent currentColor;border-radius:50%;position:absolute;transform:rotate(-45deg);animation:inner-rotate 0.5s linear infinite alternate;",
    "%_control-preloader::before": "color:#7fccf7;",
    "%_control-preloader::after": "color:#3899ec;opacity:0;",
    "@keyframes %semi-rotate": "0%{transform:rotate(180deg);animation-timing-function:ease-out;}45%{transform:rotate(198deg);}55%{transform:rotate(234deg);}100%{transform:rotate(540deg);}",
    "@keyframes %inner-rotate": "% to{transform:rotate(115deg);opacity:1;}"
  }
}
 skins['platform.components.skins.AppWidgetSkin'] = {
  "react": [
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ]
}
 skins['skins.core.InlineSkin'] = {
  "react": [
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ]
}
 skins['skins.viewer.area.ScotchDoubleHorizontalArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_scotchT"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_scotchB"
      ],
      {}
    ]
  ],
  "params": {
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "brw": "10px",
    "brd": "color_15",
    "bg": "color_11",
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%bg": "border:[brw] solid [brd];position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  [shd]",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_scotchT": "position:absolute;display:inline-block;background:url([tdr]scotcht.png) no-repeat;width:78px;height:40px;top:-20px;left:50%;margin-left:-39px;",
    "%_scotchB": "position:absolute;display:inline-block;background:url([tdr]scotchb.png) no-repeat;width:100px;height:48px;bottom:-20px;right:50%;margin-right:-50px;"
  }
}
 skins['skins.viewer.area.ScotchDoubleVerticalArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_scotchL"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_scotchR"
      ],
      {}
    ]
  ],
  "params": {
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "brw": "10px",
    "brd": "color_15",
    "bg": "color_11",
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%bg": "border:[brw] solid [brd];position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  [shd]",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_scotchL,%_scotchR": "position:absolute;display:inline-block;background-repeat:no-repeat;width:85px;height:86px;top:-20px;",
    "%_scotchL": "background-image:url([tdr]scotchl.png);left:-30px;",
    "%_scotchR": "background-image:url([tdr]scotchr.png);right:-30px;"
  }
}
 skins['skins.viewer.area.ScotchTopArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_scotch"
      ],
      {}
    ]
  ],
  "params": {
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "brw": "10px",
    "brd": "color_15",
    "bg": "color_11",
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%bg": "border:[brw] solid [brd];position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  [shd]",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_scotch": "position:absolute;display:inline-block;background:url([tdr]scotchvertical.png) no-repeat;width:42px;height:86px;top:-43px;left:50%;margin-left:-21px;"
  }
}
 skins['wysiwyg.viewer.components.GroupSkin'] = {
  "react": [
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "css": {
    "%": "pointer-events:none;",
    "%_ie10": "max-width:0;max-height:0;",
    "%inlineContent": "pointer-events:all;"
  }
}
 skins['wysiwyg.viewer.skins.FormContainerSkin'] = {
  "react": [
    [
      "form",
      "form",
      [],
      {}
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "rd": "0px",
    "shd": "0 0 0 rgba(0, 0, 0, 0)",
    "bg": "transparent",
    "brd": "#e3e3e3",
    "brw": "0"
  },
  "css": {
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%form": "[rd]  [shd]  background-color:[bg];border:solid [brd] [brw];",
    "%[data-is-responsive=\"false\"] %form": "width:100%;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.ResponsiveContainerRefSkin'] = {
  "react": [
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "css": {
    "%": "pointer-events:none !important;",
    "%inlineContent > *": "pointer-events:auto;"
  }
}
 skins['wysiwyg.viewer.skins.VerticalRepeaterEmptySkin'] = {
  "react": [
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ]
}
 skins['wysiwyg.viewer.skins.VerticalRepeaterSkin'] = {
  "react": [
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "pad": "PADDING_SIZE",
    "bgh": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "brw": "1px",
    "brd": "color_15",
    "bg": "color_11",
    "pad": "10px 0",
    "bgh": "color_16"
  },
  "css": {
    "%inlineContent": "border:[brw] solid [brd];",
    "%inlineContent > *": "border-bottom:[brw] solid [brd];background-color:[bg];padding:[pad] 0;",
    "%inlineContent > *[data-islast=\"true\"]": "border-bottom:transparent solid 0;",
    "%inlineContent > *:hover": "background-color:[bgh];"
  }
}
 skins['wysiwyg.viewer.skins.apps.DefaultBoxSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [
        "_olo"
      ],
      {}
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "#fff",
    "rd": "0",
    "shd": "0 1px 1px rgba(0, 0, 0, 0)",
    "brw": "1px",
    "brd": "#333"
  },
  "css": {
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;box-sizing:border-box !important;background-color:[bg];[rd]  [shd]  border:[brw] solid [brd];width:100%;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.area.AppleArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "rd": "5px",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brw": "1px",
    "brd": "color_15",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  [shd]  border:[brw] solid [brd];background-image:url([tdr]apple_box.png);background-repeat:repeat-x;background-position:0 0;",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;width:100%;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.area.ArrowRightRibbon'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "rb": "BORDER_COLOR_ALPHA",
    "bg": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rb": "color_14",
    "bg": "color_11"
  },
  "css": {
    "%": "transform:scale(1);",
    "%:before": "border-width:0 20px 15px 0;bottom:0;position:absolute;content:\"\";left:0;border-style:solid;border-color:transparent [rb] transparent transparent;",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%bg": "position:absolute;top:0;right:0;bottom:15px;left:0;overflow:hidden;",
    "%bg:after,%bg:before": "position:absolute;content:\"\";overflow:hidden;right:0;width:100%;border-style:solid;border-color:[bg] transparent;",
    "%bg:after": "border-width:0 350px 499px 0;bottom:50%;",
    "%bg:before": "border-width:499px 350px 0 0;top:50%;"
  }
}
 skins['wysiwyg.viewer.skins.area.BlankAreaSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "xxx": "BG_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "xxx": "color_6",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContent": "background:[xxx] url([tdr]net.png) center center repeat;"
  }
}
 skins['wysiwyg.viewer.skins.area.BorderDashDefaultAreaSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW"
  },
  "paramsDefaults": {
    "brw": "1px",
    "brd": "color_15",
    "bg": "color_11",
    "rd": "5px",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
  },
  "css": {
    "%bg": "border:[brw] dashed [brd];position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  [shd]",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.BubbleArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_arrow"
      ],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_11",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brw": "6px",
    "brd": "color_15"
  },
  "css": {
    "%bg": "[rd]  background-color:[bg];[shd]  border:[brw] solid [brd];position:absolute;top:0;right:0;bottom:10px;left:0;",
    "%_arrow": "position:absolute;bottom:0;left:50%;margin-left:-10px;border:solid transparent;border-width:10px 10px 0;border-top-color:[brd];",
    "%inlineContent": "[rd]  position:absolute;top:0;right:0;bottom:30px;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.BubbleAreaLeft'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_arrow"
      ],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_11",
    "shd": "0 1px 4px 2px rgba(0, 0, 0, 0.6)",
    "brw": "6px",
    "brd": "color_15"
  },
  "css": {
    "%bg": "[rd]  background-color:[bg];[shd]  border:[brw] solid [brd];position:absolute;top:0;right:0;bottom:0;left:10px;",
    "%_arrow": "position:absolute;left:0;top:50%;margin-top:-10px;border:solid transparent;border-width:10px 10px 10px 0;border-right-color:[brd];",
    "%inlineContent": "[rd]  position:absolute;top:0;right:0;bottom:10px;left:-10px;"
  }
}
 skins['wysiwyg.viewer.skins.area.BubbleAreaRight'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_arrow"
      ],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_11",
    "shd": "0 1px 4px 2px rgba(0, 0, 0, 0.6)",
    "brw": "6px",
    "brd": "color_15"
  },
  "css": {
    "%bg": "[rd]  background-color:[bg];[shd]  border:[brw] solid [brd];position:absolute;top:0;right:10px;bottom:0;left:0;",
    "%_arrow": "position:absolute;right:0;top:50%;margin-top:-10px;border:solid transparent;border-width:10px 0 10px 10px;border-left-color:[brd];",
    "%inlineContent": "[rd]  position:absolute;top:0;right:-10px;bottom:10px;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.BubbleLeftArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_arrow"
      ],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_11",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brw": "6px",
    "brd": "color_15"
  },
  "css": {
    "%bg": "[rd]  background-color:[bg];[shd]  border:[brw] solid [brd];position:absolute;top:0;right:0;bottom:10px;left:0;",
    "%_arrow": "position:absolute;bottom:0;left:10%;border:solid transparent;border-width:10px 10px 0;border-top-color:[brd];",
    "%inlineContent": "[rd]  position:absolute;top:0;right:0;bottom:30px;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.CenterRibbon'] = {
  "react": [
    [
      "div",
      null,
      [
        "_container"
      ],
      {},
      [
        "div",
        null,
        [
          "_top"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_bot"
        ],
        {}
      ],
      [
        "div",
        "bg",
        [],
        {}
      ],
      [
        "div",
        "inlineContent",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "rb2": "BORDER_COLOR_ALPHA",
    "rb1": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "brw": "2px",
    "brd": "color_15",
    "rb2": "color_14",
    "rb1": "color_14"
  },
  "css": {
    "%_container": "top:0;right:0;bottom:0;left:0;min-width:80px !important;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;margin:0 40px;[shd]  background:[bg];border:[brw] solid [brd];",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_top,%_bot": "overflow:hidden;",
    "%_top:after,%_top:before,%_bot:after,%_bot:before": "position:absolute;content:\"\";overflow:hidden;",
    "%_top:after,%_bot:after": "left:0;border-left:60px solid transparent;border-right:0;",
    "%_top:before,%_bot:before": "right:0;border-right:60px solid transparent;border-left:0;",
    "%_top": "position:absolute;top:0;right:0;bottom:50%;left:0;margin-top:10px;",
    "%_top:before,%_top:after": "top:0;border-top:100px solid;",
    "%_top:before": "border-top-color:[rb2];",
    "%_top:after": "border-top-color:[rb1];",
    "%_bot": "position:absolute;top:50%;right:0;bottom:0;left:0;margin-bottom:10px;",
    "%_bot:before,%_bot:after": "bottom:0;border-bottom:100px solid;",
    "%_bot:before": "border-bottom-color:[rb2];",
    "%_bot:after": "border-bottom-color:[rb1];"
  }
}
 skins['wysiwyg.viewer.skins.area.CircleArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW"
  },
  "paramsDefaults": {
    "brw": "3px",
    "brd": "color_15",
    "bg": "color_11",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
  },
  "css": {
    "%bg": "border:[brw] solid [brd];background-color:[bg];[shd]",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent,%bg": "position:absolute;top:0;right:0;bottom:0;left:0;border-radius:50%;"
  }
}
 skins['wysiwyg.viewer.skins.area.CleanZoomAreaSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "css": {
    "%inlineContent": "box-sizing:border-box !important;",
    "%_wixAppsLink img": "cursor:pointer;"
  }
}
 skins['wysiwyg.viewer.skins.area.CustomRibbonArea'] = {
  "react": [
    [
      "span",
      null,
      [
        "_left"
      ],
      {}
    ],
    [
      "span",
      null,
      [
        "_right"
      ],
      {}
    ],
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "rb3": "BORDER_COLOR_ALPHA",
    "rb1": "BORDER_COLOR_ALPHA",
    "rb2": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "rb3": "color_15",
    "rb1": "color_14",
    "rb2": "color_15"
  },
  "css": {
    "%bg": "background:[bg];",
    "%inlineContent,%bg": "position:absolute;top:0;right:25px;bottom:10px;left:25px;",
    "%_left,%_right": "content:\"\";position:absolute;width:0;height:0;bottom:0;border:20px solid;transform:rotate(360deg);",
    "%_left:after,%_right:after": "content:\"\";position:absolute;width:0;height:0;bottom:-20px;border:5px solid;border-color:[rb3] [rb3] transparent;",
    "%_left": "left:0;border-color:[rb1];border-left:15px solid transparent;",
    "%_left:after": "left:10px;border-left:5px solid transparent;",
    "%_right": "right:0;border-color:[rb2];border-right:15px solid transparent;",
    "%_right:after": "right:10px;border-right:5px solid transparent;"
  }
}
 skins['wysiwyg.viewer.skins.area.DBDefaultAreaSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "sz1": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg2": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "sz2": "SIZE",
    "sz3": "BORDER_SIZE",
    "brd2": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "sz1": "3px",
    "brd": "color_15",
    "bg2": "color_16",
    "rd": "0",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "sz2": "5px",
    "sz3": "1px",
    "brd2": "color_14",
    "bg": "color_11"
  },
  "css": {
    "%bg": "border:[sz1] solid [brd];position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg2];[rd]  [shd]",
    "%bg:after": "position:absolute;top:[sz2];right:[sz2];bottom:[sz2];left:[sz2];content:\"\";[rd]    border:[sz3] solid [brd2];background-color:[bg];",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.DefaultAreaSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW"
  },
  "paramsDefaults": {
    "brw": "1px",
    "brd": "color_15",
    "bg": "color_11",
    "rd": "5px",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
  },
  "css": {
    "%bg": "border:[brw] solid [brd];background-color:[bg];[rd]  [shd]position:absolute;top:0;right:0;bottom:0;left:0;",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.ForkedRibbonArea'] = {
  "react": [
    [
      "div",
      null,
      [
        "_container"
      ],
      {},
      [
        "b",
        null,
        [
          "_top"
        ],
        {}
      ],
      [
        "b",
        null,
        [
          "_bot"
        ],
        {}
      ],
      [
        "b",
        null,
        [
          "_ctr"
        ],
        {}
      ],
      [
        "div",
        "bg",
        [],
        {}
      ],
      [
        "div",
        "inlineContent",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "rb3": "BORDER_COLOR_ALPHA",
    "rb4": "BORDER_COLOR_ALPHA",
    "rb1": "BORDER_COLOR_ALPHA",
    "rb2": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brw": "2px",
    "brd": "color_14",
    "rb3": "color_15",
    "rb4": "color_15",
    "rb1": "color_14",
    "rb2": "color_14"
  },
  "css": {
    "%_container": "top:0;right:0;bottom:0;left:0;min-width:80px !important;",
    "%bg": "box-sizing:border-box;position:absolute;top:0;right:0;bottom:0;left:0;background:[bg];margin:0 50px 14px;[shd]  border:[brw] solid [brd];",
    "%[data-is-absolute-layout=\"true\"] > %_container > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "% b%_ctr": "position:absolute;top:0;right:0;bottom:0;left:0;margin:0 50px 20px;",
    "% b%_ctr:after,% b%_ctr:before": "position:absolute;content:\"\";overflow:hidden;bottom:-20px;border-style:solid;",
    "% b%_ctr:after": "left:0;border-width:0 20px 14px 0;border-color:transparent [rb3] transparent transparent;",
    "% b%_ctr:before": "right:0;border-width:0 0 14px 20px;border-color:transparent transparent transparent [rb4];",
    "% b%_top,% b%_bot": "overflow:hidden;position:absolute;left:0;right:0;",
    "% b%_top:before,% b%_top:after,% b%_bot:before,% b%_bot:after": "position:absolute;content:\"\";overflow:hidden;width:10px;",
    "% b%_top:before,% b%_bot:before": "right:0;border-right:60px solid transparent;border-left:0;",
    "% b%_top:after,% b%_bot:after": "left:0;border-left:60px solid transparent;border-right:0;",
    "% b%_top": "top:14px;bottom:50%;margin-bottom:-7px;",
    "% b%_top:after": "top:0;border-top:100px solid [rb1];",
    "% b%_top:before": "top:0;border-top:100px solid [rb2];",
    "% b%_bot": "top:50%;bottom:0;margin-top:7px;",
    "% b%_bot:after": "bottom:0;border-bottom:100px solid [rb1];",
    "% b%_bot:before": "bottom:0;border-bottom:100px solid [rb2];"
  }
}
 skins['wysiwyg.viewer.skins.area.ForkedRightRibbon'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {},
      [
        "div",
        null,
        [
          "_container",
          "_top"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_container",
          "_bottom"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbon"
          ],
          {}
        ]
      ]
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BORDER_COLOR_ALPHA",
    "rb1": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "rb1": "color_14"
  },
  "css": {
    "%bg": "position:absolute;top:0;bottom:15px;left:0;width:50%;background-color:[bg];",
    "%bg:after": "border-width:0 20px 15px 0;bottom:-15px;position:absolute;content:\"\";left:0;border-style:solid;border-color:transparent [rb1] transparent transparent;",
    "%_container": "height:50%;overflow:hidden;width:100%;left:100%;",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_bottom": "bottom:0;",
    "%_bottom %_ribbon": "bottom:0;border-color:transparent transparent transparent [bg];border-width:500px 0 0 0 500px 0;",
    "%_ribbon": "position:absolute;width:0;height:0;right:0;border-style:solid;",
    "%_top %_ribbon": "top:0;border-width:500px 0 500px 0 0 0;border-color:[bg] transparent transparent;"
  }
}
 skins['wysiwyg.viewer.skins.area.GridArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;background:#fff url([tdr]grid.png) repeat-y 50% 0;",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;width:100%;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.area.InnerMarginAreaSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "$BorderRadius": "BORDER_RADIUS",
    "$boxShadow": "BOX_SHADOW",
    "$bgColor": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "$BorderRadius": "10px",
    "$boxShadow": "inset 1px 1px 6px rgba(255, 255, 255, 0.9), inset -1px -1px 6px rgba(0, 0, 0, 0.9), 0 1px 2px rgba(0, 0, 0, 0.7)",
    "$bgColor": "#000"
  },
  "css": {
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;[$BorderRadius]  [$boxShadow]  background-image:none;background-color:[$bgColor];",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:10px;bottom:10px;"
  }
}
 skins['wysiwyg.viewer.skins.area.InnerShadowAreaSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "shd": "BOX_SHADOW"
  },
  "paramsDefaults": {
    "rd": "5px",
    "bg": "color_11",
    "brd": "color_15",
    "brw": "1px",
    "shd": "inset 0 1px 2px rgba(0, 0, 0, 0.6), inset 0 -1px 1px rgba(255, 255, 255, 0.75)"
  },
  "css": {
    "%bg": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];border:solid [brd] [brw];[shd]",
    "%inlineContent": "position:absolute;width:100%;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.area.IronBox'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "glow",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_screwTL",
        "_screw"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_screwTR",
        "_screw"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_screwBL",
        "_screw"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_screwBR",
        "_screw"
      ],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "tdr": "URL",
    "ishd": "BOX_SHADOW"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "shd": "0 2px 5px rgba(0, 0, 0, 0.53)",
    "rd": "2px",
    "tdr": "BASE_THEME_DIRECTORY",
    "ishd": "inset 0 0 6px 0 rgba(255, 255, 255, 0.59), inset 0 1px 0 0 rgba(255, 255, 255, 0.92), inset 0 0 5px 0 rgba(255, 255, 255, 0.2)"
  },
  "css": {
    "%[data-is-absolute-layout=\"true\"] > %inlineContent,%bg,%glow": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%bg": "background-color:[bg];[shd]  [rd]  background-image:url([tdr]ironpatern.png);",
    "%glow": "[ishd]  [rd]",
    "%_screw": "display:inline-block;background:url([tdr]skrew.png) no-repeat;width:15px;height:15px;",
    "%_screwTL,%_screwTR,%_screwBL,%_screwBR": "position:absolute;",
    "%_screwTL": "top:5px;left:5px;",
    "%_screwTR": "top:5px;right:5px;",
    "%_screwBL": "bottom:5px;left:5px;",
    "%_screwBR": "bottom:5px;right:5px;"
  }
}
 skins['wysiwyg.viewer.skins.area.LeftTriangleArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_arrow"
      ],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BORDER_COLOR_ALPHA",
    "clr": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "clr": [
      "bg"
    ]
  },
  "css": {
    "%bg": "background-color:[bg];border:solid [bg];position:absolute;top:0;right:0;bottom:23px;left:0;",
    "%_arrow": "position:absolute;bottom:-18px;left:0;border-top:18px solid [clr];border-bottom:23px solid transparent;border-right:23px solid transparent;",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:5px;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.LiftedBottomAreaSkin'] = {
  "react": [
    [
      "div",
      null,
      [
        "_shadow",
        "_leftBottom"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadow",
        "_centerBottom"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadow",
        "_rightBottom"
      ],
      {}
    ],
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL",
    "bg": "BG_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "bg": "color_11",
    "brw": "1px",
    "brd": "color_15"
  },
  "css": {
    "%_shadow": "position:absolute;top:-15px;bottom:-15px;background:url([tdr]shdbottom.png) no-repeat;pointer-events:none;",
    "%_leftBottom": "left:-15px;background-position:left bottom;width:50px;",
    "%_rightBottom": "right:-15px;background-position:right bottom;width:50px;",
    "%_centerBottom": "right:35px;left:35px;background-position:center bottom;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];border:[brw] solid [brd];",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;width:100%;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.area.LiftedShadowArea'] = {
  "react": [
    [
      "div",
      null,
      [
        "_left",
        "_shd"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_right",
        "_shd"
      ],
      {}
    ],
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "rd": "5px",
    "brw": "1px",
    "brd": "color_15",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  border:[brw] solid [brd];",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_shd": "position:absolute;bottom:-26px;width:165px;height:26px;background:url([tdr]liftedshadow_medium.png) no-repeat;pointer-events:none;",
    "%_left": "left:-20px;background-position:0 0;",
    "%_right": "right:-20px;background-position:100% 0;"
  }
}
 skins['wysiwyg.viewer.skins.area.LiftedTopAreaSkin'] = {
  "react": [
    [
      "div",
      null,
      [
        "_shadow",
        "_leftTop"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadow",
        "_centerTop"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadow",
        "_rightTop"
      ],
      {}
    ],
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL",
    "bg": "BG_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "bg": "color_11",
    "brw": "1px",
    "brd": "color_15"
  },
  "css": {
    "%_shadow": "position:absolute;top:-15px;bottom:-15px;background-image:url([tdr]shdtop.png);background-repeat:no-repeat;pointer-events:none;",
    "%_leftTop": "left:-15px;background-position:left top;width:50px;",
    "%_rightTop": "right:-15px;background-position:right top;width:50px;",
    "%_centerTop": "right:35px;left:35px;background-position:center top;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];border:[brw] solid [brd];",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;width:100%;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.area.LinesAreaSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "brwT": "BORDER_TOP_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "brwB": "BORDER_BOTTOM_SIZE",
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW"
  },
  "paramsDefaults": {
    "brwT": "7px",
    "brd": "color_15",
    "brwB": "0",
    "bg": "color_11",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
  },
  "css": {
    "%bg": "border-top:calc([brwT] * 4) solid [brd];border-bottom:calc([brwB] * 4) solid [brd];position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[shd]",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.PhotoArea'] = {
  "react": [
    [
      "div",
      null,
      [
        "_shadowImgTL",
        "_bgShadow"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadowImgTR",
        "_bgShadow"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadowImgBL",
        "_bgShadow"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadowImgBR",
        "_bgShadow"
      ],
      {}
    ],
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "shd": "0 1px 5px rgba(0, 0, 0, 0.1)",
    "brw": "10px",
    "brd": "color_15",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[shd]  border:[brw] solid [brd];",
    "%_bgShadow": "background:url([tdr]shadow_photo.png) no-repeat;width:168px;height:154px;",
    "%_shadowImgTL,%_shadowImgTR,%_shadowImgBL,%_shadowImgBR": "position:absolute;",
    "%_shadowImgTL": "background-position:0 0;left:-13px;top:-15px;",
    "%_shadowImgTR": "background-position:100% 0;right:-15px;top:-14px;",
    "%_shadowImgBL": "background-position:0 100%;left:-14px;bottom:-13px;",
    "%_shadowImgBR": "background-position:100% 100%;right:-16px;bottom:-14px;",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;width:100%;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.area.RectangleArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_11"
  },
  "css": {
    "%bg": "overflow:hidden;position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.RectangleAreaAfterScroll'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "bg-scrl": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "bg-scrl": "color_11"
  },
  "css": {
    "%bg": "overflow:hidden;position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%[data-state~=\"scrolled\"] %bg,%[data-preview~=\"scrolled\"] %bg": "background-color:[bg-scrl];",
    "%[data-state~=\"transition-allowed\"] %bg": "transition:all 0.3s ease;"
  }
}
 skins['wysiwyg.viewer.skins.area.RibbonAreaSkin'] = {
  "react": [
    [
      "div",
      null,
      [
        "_fl",
        "_ribbon"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_fr",
        "_ribbon"
      ],
      {}
    ],
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "els": "BORDER_SIZE",
    "elm": "COLOR_ALPHA",
    "elm2": "COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brw": "6px",
    "brd": "color_15",
    "els": "0",
    "elm": "color_15",
    "elm2": "color_15"
  },
  "css": {
    "%bg": "background-color:[bg];[shd]  border:[brw] solid [brd];position:absolute;top:0;right:0;bottom:0;left:0;bottom:calc([els] + 10px);",
    "%_ribbon": "position:absolute;bottom:calc(-1 * ([els] + 10px));height:0;width:0;border:calc([els] + 10px) solid transparent;",
    "%_fr": "border-top-color:[elm];border-left-width:0;right:0;",
    "%_fl": "border-top-color:[elm2];border-right-width:0;left:0;",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;bottom:calc([els] + 10px);"
  }
}
 skins['wysiwyg.viewer.skins.area.RightTriangleArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_arrow"
      ],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BORDER_COLOR_ALPHA",
    "clr": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "clr": [
      "bg"
    ]
  },
  "css": {
    "%bg": "background-color:[bg];border:solid [bg];position:absolute;top:0;right:0;bottom:23px;left:0;",
    "%_arrow": "position:absolute;bottom:-18px;right:0;border-top:18px solid [clr];border-bottom:23px solid transparent;border-left:23px solid transparent;",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:5px;left:0;min-height:30px;min-width:25px;"
  }
}
 skins['wysiwyg.viewer.skins.area.RoundArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "xxx": "BG_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "brd": "color_15",
    "bg": "color_11",
    "rd": "5px",
    "xxx": "color_11",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%bg": "border:3px solid [brd];position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  background:[xxx] url([tdr]net.png) center center repeat;",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.RoundShadowArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "xxx": "BG_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "brw": "15px",
    "brd": "color_15",
    "bg": "color_11",
    "rd": "5px",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "xxx": "color_11",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%bg": "overflow:hidden;border:[brw] solid [brd];background-color:[bg];[rd]  [shd]  background:[xxx] url([tdr]net.png) center center repeat;",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent,%bg": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.SandClockArea'] = {
  "react": [
    [
      "b",
      null,
      [
        "_tl"
      ],
      {}
    ],
    [
      "b",
      null,
      [
        "_tr"
      ],
      {}
    ],
    [
      "b",
      null,
      [
        "_bl"
      ],
      {}
    ],
    [
      "b",
      null,
      [
        "_br"
      ],
      {}
    ],
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_11"
  },
  "css": {
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "% b": "position:absolute;width:50%;height:50%;overflow:hidden;",
    "% b%_tr": "left:50%;",
    "% b%_tr:after": "top:0;right:0;border-top-color:[bg];border-right-width:500px;",
    "% b%_br": "top:50%;left:50%;",
    "% b%_br:after": "bottom:0;right:0;border-bottom-color:[bg];border-right-width:500px;",
    "% b%_bl": "top:50%;",
    "% b%_bl:after": "bottom:0;left:0;border-bottom-color:[bg];border-left-width:500px;",
    "% b%_tl:after": "top:0;left:0;border-top-color:[bg];border-left-width:500px;",
    "% b:after": "width:100%;height:100%;position:absolute;content:\"\";border:1000px solid transparent;"
  }
}
 skins['wysiwyg.viewer.skins.area.SloopyArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_bg2"
      ],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%bg,%_bg2,%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:3px;right:3px;bottom:3px;left:3px;",
    "%bg": "background:[bg];",
    "%bg:before,%bg:after": "position:absolute;content:\"\";background:url([tdr]sloppyhoriz.png) repeat-x;left:-7px;right:-7px;height:10px;",
    "%bg:before": "background-position:0 0;top:-7px;",
    "%bg:after": "background-position:0 100%;bottom:-7px;",
    "%_bg2:before,%_bg2:after": "position:absolute;content:\"\";background:url([tdr]sloppyvertical.png) repeat-y;top:0;bottom:0;width:10px;",
    "%_bg2:before": "background-position:0 0;left:-7px;",
    "%_bg2:after": "background-position:100% 0;right:-7px;"
  }
}
 skins['wysiwyg.viewer.skins.area.ThreeDeeAreaSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "shc": "COLOR"
  },
  "paramsDefaults": {
    "rd": "5px",
    "bg": "color_11",
    "brd": "color_15",
    "brw": "1px",
    "shc": [
      "bg"
    ]
  },
  "paramsMutators": {
    "shc": {
      "type": "brightness",
      "value": 0.5,
      "param": "bg"
    }
  },
  "css": {
    "%bg": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];border:solid [brd] [brw];box-shadow:1px 1px [shc], 3px 3px [shc], 5px 5px [shc], 7px 7px [shc], 9px 9px [shc];",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.TiltedAreaSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW"
  },
  "paramsDefaults": {
    "brw": "1px",
    "brd": "color_15",
    "bg": "color_11",
    "rd": "5px",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
  },
  "css": {
    "%bg": "border:[brw] solid [brd];position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  [shd]",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.TransparentArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "xxx": "BG_COLOR_ALPHA",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "rd": "5px",
    "xxx": "color_11",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%bg": "overflow:hidden;position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  opacity:0.7;background:[xxx] url([tdr]net.png) center center repeat;",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.VerticalArrowArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "b",
      null,
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_11"
  },
  "css": {
    "%bg": "position:absolute;top:0;right:0;bottom:200px;left:0;background:[bg];",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "% b": "position:absolute;top:0;right:0;bottom:0;left:0;display:block;overflow:hidden;",
    "% b:after,% b:before": "position:absolute;content:\"\";border-style:solid;transform:rotate(360deg);bottom:0;",
    "% b:after": "border-width:0 500px 200px 0;border-color:transparent [bg] transparent transparent;right:50%;",
    "% b:before": "border-width:0 0 200px 500px;border-color:transparent transparent transparent [bg];left:50%;right:0;"
  }
}
 skins['wysiwyg.viewer.skins.area.VerticalRibbonArea'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "i",
      null,
      [],
      {},
      [
        "b",
        null,
        [
          "_l"
        ],
        {}
      ],
      [
        "b",
        null,
        [
          "_r"
        ],
        {}
      ]
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_11"
  },
  "css": {
    "%": "transform:rotate(360deg);",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;bottom:315px;background:[bg];min-height:50%;",
    "%[data-is-absolute-layout=\"true\"] > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "% i": "position:absolute;top:50%;right:0;bottom:0;left:0;overflow:hidden;",
    "%[data-state~=\"mobileView\"] i": "margin-top:-1px;",
    "% b": "position:absolute;display:block;top:100%;margin-top:-500px;overflow:hidden;bottom:0;",
    "%_l": "left:0;right:50%;",
    "%_l:after": "left:0;border-width:0 500px 315px 500px;border-color:[bg] transparent transparent [bg];",
    "%_r": "right:0;left:50%;",
    "%_r:before": "right:0;border-width:0 500px 315px 0;border-color:transparent [bg] transparent [bg];",
    "%_l:after,%_r:before": "position:absolute;bottom:0;content:\"\";border-style:solid;overflow:hidden;"
  }
}
 skins['wysiwyg.viewer.skins.area.WrapperSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR"
  },
  "paramsDefaults": {
    "bg": "#fff",
    "rd": "0",
    "shd": "0 1px 3px rgba(0, 0, 0, 0)",
    "brw": "1px",
    "brd": "#000"
  },
  "css": {
    "%": "background-color:[bg];[rd]  [shd]  box-sizing:border-box !important;border:[brw] solid [brd];position:relative;height:auto !important;",
    "%bg": "display:none;",
    "%inlineContent": "position:relative;"
  }
}

module.exports = skins